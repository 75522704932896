import React from "react";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import classes from "../styles/TravelHelp.module.css";
import Image from "next/image";
import VisaIcon from "./VisaIcon";
import { ephesis, handlee } from "@/utils/fonts";
import { Typography } from "@mui/material";

const TravelHelpCard = () => {
  return (
    <Card className={classes.cardContainer}>
      <div className={classes.wrapper}>
        <Stack justifyContent={"center"} alignItems={"center"}>
          <Typography
            textAlign={"center"}
            className={`${classes.title} ${ephesis.className}`}
          >
            ¡Ya tenemos el refri! ¿Nos ayudas a llenarlo con una donación para
            el súper?
          </Typography>

          <Stack
            direction={{
              xs: "column",
              sm: "row",
              tablet: "column",
              md: "column",
              lg: "row",
              xl: "row",
            }}
            spacing={2.5}
            justifyContent={"center"}
          >
            <div className={classes.card}>
              <div className={classes.cardInfo}>
                <div className={classes.cardLogo}>BBVA</div>
                <div className={classes.cardChip}>
                  <svg
                    className={classes.cardChipLines}
                    role="img"
                    width="20px"
                    height="20px"
                    viewBox="0 0 100 100"
                    aria-label="Chip"
                  >
                    <g opacity="0.8">
                      <polyline
                        points="0,50 35,50"
                        fill="none"
                        stroke="#000"
                        strokeWidth="2"
                      ></polyline>
                      <polyline
                        points="0,20 20,20 35,35"
                        fill="none"
                        stroke="#000"
                        strokeWidth="2"
                      ></polyline>
                      <polyline
                        points="50,0 50,35"
                        fill="none"
                        stroke="#000"
                        strokeWidth="2"
                      ></polyline>
                      <polyline
                        points="65,35 80,20 100,20"
                        fill="none"
                        stroke="#000"
                        strokeWidth="2"
                      ></polyline>
                      <polyline
                        points="100,50 65,50"
                        fill="none"
                        stroke="#000"
                        strokeWidth="2"
                      ></polyline>
                      <polyline
                        points="35,35 65,35 65,65 35,65 35,35"
                        fill="none"
                        stroke="#000"
                        strokeWidth="2"
                      ></polyline>
                      <polyline
                        points="0,80 20,80 35,65"
                        fill="none"
                        stroke="#000"
                        strokeWidth="2"
                      ></polyline>
                      <polyline
                        points="50,100 50,65"
                        fill="none"
                        stroke="#000"
                        strokeWidth="2"
                      ></polyline>
                      <polyline
                        points="65,65 80,80 100,80"
                        fill="none"
                        stroke="#000"
                        strokeWidth="2"
                      ></polyline>
                    </g>
                  </svg>
                  <div className={classes.cardChipTexture}></div>
                </div>

                <Stack width={"100%"} style={{ marginBlock: ".5rem" }}>
                  <div className={classes.cardNumber}>
                    <span
                      className={`${classes.cardDigitGroup} ${handlee.className}`}
                    >
                      TARJETA:
                    </span>
                    <span
                      className={`${classes.cardDigitGroup} ${handlee.className}`}
                    >
                      4152
                    </span>
                    <span
                      className={`${classes.cardDigitGroup} ${handlee.className}`}
                    >
                      3137
                    </span>
                    <span
                      className={`${classes.cardDigitGroup} ${handlee.className}`}
                    >
                      8952
                    </span>
                    <span
                      className={`${classes.cardDigitGroup} ${handlee.className}`}
                    >
                      8929
                    </span>
                  </div>

                  <div className={classes.cardNumber}>
                    <span
                      className={`${classes.cardDigitGroup} ${handlee.className}`}
                    >
                      CLABE:
                    </span>
                    <span
                      className={`${classes.cardDigitGroup} ${handlee.className}`}
                    >
                      012180004706475940
                    </span>
                  </div>

                  <div className={classes.cardNumber}>
                    <span
                      className={`${classes.cardDigitGroup} ${handlee.className}`}
                    >
                      CUENTA:
                    </span>
                    <span
                      className={`${classes.cardDigitGroup} ${handlee.className}`}
                    >
                      0470647594
                    </span>
                  </div>
                </Stack>

                <Stack
                  direction="row"
                  width={"100%"}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <div
                    className={`${classes.cardName} ${handlee.className}`}
                    aria-label="Owner Name"
                  >
                    Rosa Olvera
                  </div>

                  <VisaIcon />
                </Stack>

                <div className={classes.cardTexture}></div>
              </div>
            </div>

            <div className={classes.card}>
              <div className={classes.cardInfo}>
                <div className={classes.cardLogo}>BBVA</div>
                <div className={classes.cardChip}>
                  <svg
                    className={classes.cardChipLines}
                    role="img"
                    width="20px"
                    height="20px"
                    viewBox="0 0 100 100"
                    aria-label="Chip"
                  >
                    <g opacity="0.8">
                      <polyline
                        points="0,50 35,50"
                        fill="none"
                        stroke="#000"
                        strokeWidth="2"
                      ></polyline>
                      <polyline
                        points="0,20 20,20 35,35"
                        fill="none"
                        stroke="#000"
                        strokeWidth="2"
                      ></polyline>
                      <polyline
                        points="50,0 50,35"
                        fill="none"
                        stroke="#000"
                        strokeWidth="2"
                      ></polyline>
                      <polyline
                        points="65,35 80,20 100,20"
                        fill="none"
                        stroke="#000"
                        strokeWidth="2"
                      ></polyline>
                      <polyline
                        points="100,50 65,50"
                        fill="none"
                        stroke="#000"
                        strokeWidth="2"
                      ></polyline>
                      <polyline
                        points="35,35 65,35 65,65 35,65 35,35"
                        fill="none"
                        stroke="#000"
                        strokeWidth="2"
                      ></polyline>
                      <polyline
                        points="0,80 20,80 35,65"
                        fill="none"
                        stroke="#000"
                        strokeWidth="2"
                      ></polyline>
                      <polyline
                        points="50,100 50,65"
                        fill="none"
                        stroke="#000"
                        strokeWidth="2"
                      ></polyline>
                      <polyline
                        points="65,65 80,80 100,80"
                        fill="none"
                        stroke="#000"
                        strokeWidth="2"
                      ></polyline>
                    </g>
                  </svg>
                  <div className={classes.cardChipTexture}></div>
                </div>

                <Stack width={"100%"} style={{ marginBlock: ".5rem" }}>
                  <div className={classes.cardNumber}>
                    <span
                      className={`${classes.cardDigitGroup} ${handlee.className}`}
                    >
                      TARJETA:
                    </span>
                    <span
                      className={`${classes.cardDigitGroup} ${handlee.className}`}
                    >
                      4152
                    </span>
                    <span
                      className={`${classes.cardDigitGroup} ${handlee.className}`}
                    >
                      3140
                    </span>
                    <span
                      className={`${classes.cardDigitGroup} ${handlee.className}`}
                    >
                      2624
                    </span>
                    <span
                      className={`${classes.cardDigitGroup} ${handlee.className}`}
                    >
                      3678
                    </span>
                  </div>

                  <div className={classes.cardNumber}>
                    <span
                      className={`${classes.cardDigitGroup} ${handlee.className}`}
                    >
                      CLABE:
                    </span>
                    <span
                      className={`${classes.cardDigitGroup} ${handlee.className}`}
                    >
                      012180015399418730
                    </span>
                  </div>

                  <div className={classes.cardNumber}>
                    <span
                      className={`${classes.cardDigitGroup} ${handlee.className}`}
                    >
                      CUENTA:
                    </span>
                    <span
                      className={`${classes.cardDigitGroup} ${handlee.className}`}
                    >
                      1539941873
                    </span>
                  </div>
                </Stack>

                <Stack
                  direction="row"
                  width={"100%"}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <div
                    className={`${classes.cardName} ${handlee.className}`}
                    aria-label="Owner Name"
                  >
                    Adrián Miranda
                  </div>

                  <VisaIcon />
                </Stack>

                <div className={classes.cardTexture}></div>
              </div>
            </div>
          </Stack>
        </Stack>
      </div>
    </Card>
  );
};

export default TravelHelpCard;
