import Image from "next/image";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Background, Parallax } from "react-parallax";

import useBreakpoints from "@/hooks/useBreakpoints";
import GiftTableCard from "@/components/GiftTableCard";
import Link from "next/link";
import TravelHelpCard from "@/components/TravelHelpCard";

const GiftsTable = () => {
  const { smartphone, mobile, tablet, laptop, desktop } = useBreakpoints();

  return (
    <Parallax strength={800}>
      <Background className={"parallaxImage"}>
        <Image
          alt="Gifts parallax Image"
          src={
            smartphone || mobile || tablet
              ? "/marquesa_mobile.webp"
              : "/marquesa_desk.webp"
          }
          loading={"lazy"}
          fill
          quality={100}
          style={{
            objectFit: "cover",
          }}
        />
      </Background>

      <Box
        height={{
          xs: "100vh",
          sm: "100vh",
          tablet: "70vh",
        }}
      >
        <Stack
          direction={tablet || laptop || desktop ? "row" : "column"}
          justifyContent={"space-evenly"}
          alignItems={"center"}
          height={"100%"}
          spacing={2}
        >
          <GiftTableCard
            altText="fotoTequis"
            srcImage="/be.webp"
            title="Mesa de Regalos Amazon"
            altOverText="amazonGift"
            srcOverImage="/amazonGift.png"
          />

          <TravelHelpCard />
        </Stack>
      </Box>
    </Parallax>
  );
};

export default GiftsTable;
