import React from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Image from "next/image";
import classes from "../styles/GiftTable.module.css";
import { ephesis } from "@/utils/fonts";

interface GiftTableCardProps {
  altText: string;
  srcImage: string;
  title: string;
  altOverText: string;
  srcOverImage: string;
}

const GiftTableCard = ({
  altText,
  srcImage,
  title,
  altOverText,
  srcOverImage,
}: GiftTableCardProps) => {
  return (
    <Card className={classes.cardContainer}>
      <div className={classes.giftCard}>
        <div className={classes.wrapper}>
          <Image
            alt={altText}
            src={srcImage}
            className={classes["cover-image"]}
            quality={100}
            fill
            loading={"lazy"}
          />
        </div>
        {altText === "mar" ? (
          <Typography className={`${classes.title} ${ephesis.className}`}>
            {title}
          </Typography>
        ) : (
          <a
            href={
              "https://www.amazon.com.mx/wedding/rosa-olvera-adrian-miranda-acapulco-january-2024/registry/SPEEEZMFTPBS"
            }
            target="_blank"
          >
            <Typography className={`${classes.title} ${ephesis.className}`}>
              {title}
            </Typography>
          </a>
        )}
        <Image
          alt={altOverText}
          src={srcOverImage}
          width={100}
          height={100}
          className={classes.gift}
          loading={"lazy"}
          unoptimized={true}
        />
      </div>
    </Card>
  );
};

export default GiftTableCard;
